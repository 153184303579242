* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-family: 'Open Sans', 'Roboto', 'Times New Roman', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 62.5%;

}

@media only screen and (max-width:1200px) {
  html{
    font-size: 50%;
  }
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}